// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-page-layout-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/components/PostPageLayout.jsx" /* webpackChunkName: "component---src-components-post-page-layout-jsx" */),
  "component---src-pages-404-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aboutus-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/aboutus.jsx" /* webpackChunkName: "component---src-pages-aboutus-jsx" */),
  "component---src-pages-amz-sales-rank-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/amz-sales-rank.jsx" /* webpackChunkName: "component---src-pages-amz-sales-rank-jsx" */),
  "component---src-pages-contactus-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/contactus.jsx" /* webpackChunkName: "component---src-pages-contactus-jsx" */),
  "component---src-pages-help-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-pricing-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("/home/bender/code/bidmatik/landing/bidmatik_landing/src/pages/termsOfUse.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */)
}


module.exports = [{
      plugin: require('/home/bender/code/bidmatik/landing/bidmatik_landing/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/bender/code/bidmatik/landing/bidmatik_landing/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/bender/code/bidmatik/landing/bidmatik_landing/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.7","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images"}]},
    },{
      plugin: require('/home/bender/code/bidmatik/landing/bidmatik_landing/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/bender/code/bidmatik/landing/bidmatik_landing/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-133716590-1","head":true,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"bidmatik.com"},
    },{
      plugin: require('/home/bender/code/bidmatik/landing/bidmatik_landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
